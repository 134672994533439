<template>
  <div class="w200">
    <!--h4 class="pageName">{{ $t('front.common.mypage') }}</h4-->
    <ul class="boardnav">
      <template v-for="(item, index) in menuList">
        <li v-if="item" v-bind:key="index" :class="{'on': item.name === currentMenu}">
          <span @click="onClickNav(item.name, item)" :class="[{'submen': item.children && item.meta.lnbChildren},item.meta.lnbClass]">
            {{ $t(item.meta.text) }}
          </span>
          <div v-if="item.meta.lnbChildren && item.children && item.children.length > 0" :class="'submenucon'">
            <ul>
              <template v-for="(subItem, subIndex) in item.children">
                <li v-if="subItem" v-bind:key="subIndex" @click="onClickNav(subItem.name)" :class="{'on': $route.path.indexOf(subItem.path) > -1}">
                  {{ $t(subItem.meta.text) }}
                </li>
              </template>
            </ul>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavSide',
  props: [
    'currentMenu',
    'menuList'
  ],
  data () {
    return {
      // menuList: []
    }
  },
  created () {
    // const list = this.$router.getRoutes()
    //
    // for (let i = 0, iLen = list.length; i < iLen; i++) {
    //   const item = list[i]
    //   const routeName = item.name
    //   if (routeName === 'csCenter') {
    //     const children = item.children
    //     for (let j = 0, jLen = children.length; j < jLen; j++) {
    //       const subItem = children[j]
    //       if (subItem.meta.lnb) {
    //         this.menuList.push(subItem)
    //       }
    //     }
    //   }
    // }
  },
  methods: {
    onClickNav (name, item) {
      this.$router.push({ name: name })
    }
  }
}
</script>
<style scoped>
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
